<template>
  <div>
    <form
      action="."
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <h3 class="modal-card-title has-text-centered">
            Sign in with E-mail
          </h3>
        </header>
        <section class="modal-card-body">
          <b-field label="E-mail">
            <b-input
              v-model="emailInput"
              type="email"
            />
          </b-field>
          <b-field label="Password">
            <b-input
              v-model="passwordInput"
              type="password"
              password-reveal
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <div class="is-pulled-right">
            <button
              class="button is-medium"
              type="button"
              @click="$parent.close()"
            >
              Cancel
            </button>
            <button
              class="button is-success is-medium"
              :disabled="!isValidForm"
              type="button"
              @click="triggerLogin"
            >
              Sign in
            </button>
          </div>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginModal',
  data () {
    return {
      emailInput: '',
      passwordInput: ''
    }
  },
  computed: {
    isValidForm () {
      return this.emailInput && this.passwordInput
    }
  },
  methods: {
    triggerLogin (e) {
      this.$emit('login-click', { email: this.emailInput, password: this.passwordInput })
    }
  }
}
</script>
